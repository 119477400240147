@import url("https://fonts.googleapis.com/css?family=La Belle Aurore|Bebas Neue");

@font-face {
  font-family: myFirstFont;
  src: url("./fonts/download.ttf")
}

* {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-page h1 {
  color: #fff;
  font-size: 56px;
  line-height: 53px;
  margin: 0;
  font-family: myFirstFont;
  font-weight: 400
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #222;
  font-size: 1em;
  line-height: 1.4;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  font: 300 11px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #444;
  margin: 0;
  background: #1d1d1d;
  overflow-x: hidden;
  display: block;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

body.no-overflow {
  overflow-x: hidden;
  overflow-y: auto;
}

.logo {
  width: 60px;
}

.active-nav i {
  color: #23ffde;
}

#nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  min-height: 500px;

  nav {
    display: block;
    text-align: center;
    margin-top: auto;

    a {
      display: block;
      padding: 2em 0;
      list-style: none;
      text-align: center;
      margin: 0;
      padding-bottom: 25px;
      color: #909096;
    }

    &:hover {
      color: #23ffde;
    }
  }

  ul {
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;
    margin-top: auto;
    padding-bottom: 25px;

    li a {
      padding: 7px 0;
      display: block;
      font-size: 15px;
      line-height: 16px;
      color: #909096;

      &:hover {
        color: #23ffde;
      }
    }
  }
}

.overlay {
  position: absolute;
  background-color: #181818;
  height: 8vh;
  left: 0;
  right: 0;
  transform: translateY(-65%);
  opacity: 0;
  width: 100%;
  transition: 0.5s ease;
}

.link:hover .overlay {
  opacity: 1;
}

.text {
  color: #23ffde;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

a {
  color: #3d3d3d;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

.icons {
  margin-top: 2.5rem;
  /* border: 1px solid #979797; */
  float: left;
  -webkit-animation: myanim2 1s 1s backwards;
  animation: myanim2 1s 1s backwards;

  a {
    color: #23ffde;
    padding: 0.5rem;
    padding-right: 1rem;

    &:hover {
      color: #ffe7ee;
      transition: all 0.3s ease-out;
    }
  }
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

font {
  display: none;
}

h1 {
  font-size: 60px !important;
}

#my-lottie {
  height: 100%;
  div {
    position: absolute;
    top: 30%;
    left: 65%;
  }
}

.tags {
  color: #515152;
  position: absolute;
  bottom: 5px;
  left: 80px;
  font-size: 18px;
  font-family: "La Belle Aurore", cursive;
}

.top-tags {
  bottom: auto;
  top: 0;
  left: 80px;
}

.container {
  width: 100vw;
  height: 100%;
  opacity: 1;
  margin: 0 1rem;
  z-index: 0;
  animation: myanim2 0.5s ease-in;
}

.text-zone {
  span {
    padding: 2px;
  }

  .Slast {
    font-size: 120%;
    padding: 4px;
    color: #ff2b6b;
    text-shadow: -1px 0 #08fdd8, -2px 0 #08fdd8, -3px 0 #08fdd8;
    display: inline-block;
    transform: rotate(180deg);
    opacity: 0;
    transition: opacity 0.3s 0.5s ease, transform 0.25s 0.5s ease;
  }

  h2 {
    color: #fff;
    font-size: 16px;
  }
}

.home-page .text-zone {
  position: absolute;
  left: 10%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-height: 90%;
  max-width: 55%;
  a {
    font-weight: 400;
  }
}


.home-page {
  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-weight: 400;

    &:before {
      content: "<h1>";
      font-family: "La Belle Aurore", cursive;
      color: #515152;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
    }

    &:after {
      content: " </h1>";
      font-family: "La Belle Aurore", cursive;
      color: #515152;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 3px;
    -webkit-animation: myanim2 0.8s 0.5s backwards;
    animation: myanim2 0.8s 0.5s backwards;
  }

  .blast {
    opacity: 0;
    color: #fff;
    display: inline-block;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &:hover {
      color: #08fdd8;
    }
  }
}


.bkmrk {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;

  img {
    width: 100px;
    height: auto;
  }

  &:hover .folded-corner {
    border-width: 0 28px 28px 0;
  }

  .folded-corner {
    z-index: 100;
    width: 0;
    height: 0;
    position: absolute;
    top: -1px;
    right: -1px;
    border-width: 0;
    border-style: solid;
    background: hsla(260, 5%, 60%, 0.2);
    box-shadow: 2px -2px 0px #fff, 0 1px 1px rgba(0, 0, 0, 0.1),
    -1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0 15px;
    border-color: #fff #fff transparent transparent;
    transition: border-width 0.2s ease-out;
  }
}

.text-zone p {
  color: aliceblue;
  font-size: 1rem;
  margin: 2rem 0;
}

.startAnim {
  animation: popup 1000ms linear both;
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* ______________________________CONTACT____________________________ */
.contact {
  max-width: 35.5rem;
  margin-bottom: -40px;
}

textarea {
  resize: none;
}

#name,
#email {
  padding: 1em;
  margin: 2px 0;
  font-size: 1rem;
  background-color: #333;
  color: whitesmoke;
  border: none;
  outline: none;
}

#name {
  width: 16rem;
}

#email {
  width: 19rem;
}

#sbj,
#msg {
  padding: 1em;
  margin: 2px 0;
  width: 35.2rem;
  font-size: 1rem;
  background-color: #333;
  color: whitesmoke;
  border: none;
  outline: none;
  font-family: inherit;
}

#name:focus,
#email:focus,
#sbj:focus,
#msg:focus {
  background-color: #3d3d3d;
}

#sub-btn {
  padding: 0.5em 1em;
  font-size: 1rem;
  right: 0;
  margin-left: 30.7rem;
  background-color: transparent;
  border: #23ffde 1px solid;
  color: #23ffde;
  outline: none;

  &:hover {
    background-color: #23ffde;
    color: #1d1d1d;
  }
}

.mapouter {
  position: absolute;
  margin: 0;
  z-index: 50;
  right: 0;
  height: 100vh;
  width: 45%;
}

.gmap_canvas {
  overflow: hidden;
  background: transparent !important;
  height: 100vh;
  width: 100%;
}

/* _________________________________Keyframes___________________________________________ */
@keyframes hover {
  from,
  to {
    transition-timing-function: ease-in-out;
    transform: translate3d(0, 0, 0);
  }
  50% {
    transition-timing-function: ease-in-out;
    transform: translate3d(0, 1.5%, 0);
  }
}

@keyframes shadow {
  from,
  to {
    transition-timing-function: ease-in-out;
    transform: scale(0.95, 1);
  }
  50% {
    transition-timing-function: ease-in-out;
    transform: scale(1.05, 1);
  }
}

@keyframes popup {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  }
  4% {
    transform: matrix3d(0.32, 0, 0, 0, 0, 0.32, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6% {
    transform: matrix3d(0.632, 0, 0, 0, 0, 0.632, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  8% {
    transform: matrix3d(0.936, 0, 0, 0, 0, 0.936, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9% {
    transform: matrix3d(1.045, 0, 0, 0, 0, 1.045, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  12% {
    transform: matrix3d(1.241, 0, 0, 0, 0, 1.241, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  12% {
    transform: matrix3d(1.243, 0, 0, 0, 0, 1.243, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17% {
    transform: matrix3d(1.218, 0, 0, 0, 0, 1.218, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  20% {
    transform: matrix3d(1.124, 0, 0, 0, 0, 1.124, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28% {
    transform: matrix3d(0.993, 0, 0, 0, 0, 0.993, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  28% {
    transform: matrix3d(0.992, 0, 0, 0, 0, 0.992, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  39% {
    transform: matrix3d(0.995, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  53% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  53% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(
                    0.9999,
                    0,
                    0,
                    0,
                    0,
                    0.9999,
                    0,
                    0,
                    0,
                    0,
                    1,
                    0,
                    0,
                    0,
                    0,
                    1
    );
  }
}

@keyframes myanim2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes myanim2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/* _________________________________RESPONSIVE___________________________________________ */

@media (max-width: 1100px) and (min-width: 767px) {
  .home-page .text-zone {
    width: 70vw;
    margin-left: 10%;
  }
  .mapouter {
    display: none;
  }
  .gmap_canvas {
    display: none;
  }
  #name,
  #email {
    width: 35.2rem;
  }
  #my-lottie {
    display: none;
  }
}

@media (max-width: 767px) {
  .dg{
    display: none;
  }

  #my-lottie {
    display: none;
  }
  .container {
    top: 1%;
    height: 90%;
  }
  .mapouter {
    display: none;
  }
  .gmap_canvas {
    display: none;
  }
  .link,
  i {
    max-height: 70px;
  }
  #nav-bar a {
    display: none;
  }
  #nav-bar {
    display: flex;
    top: auto;
    bottom: 0;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    max-height: 70px;
    min-height: 0px;
    background: #181818;
    width: 100%;
    position: fixed;
  }
  #nav-bar nav {
    display: flex;
    margin: auto;
  }
  #nav-bar nav a {
    padding: 1.5em 1.8em;
    color: #909096;
  }
  #nav-bar ul li,
  .overlay,
  .text,
  .link {
    display: none;
  }
  .home-page h1:before {
    margin-top: -30px;
  }
  .text-zone h2 {
    width: 80vw;
  }

  .home-page .text-zone {
    width: 90%;
  }
  .tags {
    left: 10px;
  }
  .top-tags {
    top: 50px;
    left: 10px;
  }
  .home-page h1 {
    line-height: 30px;
  }
  .home-page .text-zone {
    top: 55%;
  }
  .text-zone {
    margin-left: 1rem;
  }
  .text-zone span {
    font-size: 2rem;
    padding: 1px;
  }
  .lnbr {
    display: inline-block;
    width: 70%;
    white-space: nowrap;
  }
  .text-zone h2 {
    font-size: 0.5rem;
  }
  .text-zone .Slast {
    font-size: 3rem;
  }
  .icons {
    font-size: 0.8em;
  }
  .contact {
    height: 60%;
  }

  #name,
  #email,
  #sbj,
  #msg {
    width: 80vw;
  }
  #name,
  #email,
  #sbj {
    font-size: 14px;
    height: 4vh;
  }
  #msg {
    font-size: 16px;
    height: 15vh;
  }

  #sub-btn {
    margin-left: 60vw;
  }
}

@media screen and (max-height: 568px) {
  .text-zone p {
    font-size: 13px;
  }
  .tags {
    display: none;
  }
  .text-zone {
    padding-right: 10%;
  }
  .text-zone h1:after {
    padding-right: 5%;
  }
}

#c{
  position: absolute !important;
}
