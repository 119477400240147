.gal-container {
  width: 100%;
  height: 100%;
  padding-left: 60px;
  left: 0;
  top: 0;
}

button {
  border: none;
  margin: 0;
}
button:focus {
  outline: none;
}

.gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  margin: 20px;
  overflow: hidden;
}
.gallery img {
  width: 100%;
  height: auto;
  transition: 500ms;
  margin-bottom: 10px;
  opacity: 1;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  transition: all 0.4s ease;
}

.box {
  transition: all 0.4s ease;
  position: relative;
  width: 100%;
}

.show1,
.show2 {
  opacity: 0;
  bottom: 10%;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: #08fdd8;
  font-size: 16px;
  font-family: "Gloria Hallelujah";
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #08fdd8;
  background-color: #1d1d1d77;
  margin-top: 25px;
  white-space: nowrap;
  transition: all 0.4s ease;
}
.show1 {
  left: 70%;
}
.show2 {
  left: 30%;
}
.prj {
  left: 50%;
  top: 0%;
  opacity: 0;
  width: 100%;
  position: absolute;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  text-align: center;
  color: #08fdd8;
  font-size: 2rem;
  font-family: "Gloria Hallelujah";
  text-decoration: none;
  color: #08fdd8;
  background: #000;
  background: -moz-linear-gradient(top, #000000aa 0%, #00000000 100%);
  background: -webkit-linear-gradient(top, #000000aa 0%, #00000000 100%);
  background: linear-gradient(to bottom, #000000aa 0%, #00000000 100%);
  padding-top: 10px;
  padding-bottom: 5px;
  white-space: nowrap;
  transition: all 0.4s ease;
}
.prj span {
  font-size: 1rem;
}

.show1:hover,
.show2:hover {
  background-color: #08fdd8;
  color: #1d1d1d;
}

.box:hover .image {
  filter: blur(1px);
  opacity: 0.7;
}
.gallery img:hover {
  filter: blur(1px);
  opacity: 0.7;
}

.box:hover .show1,
.box:hover .prj,
.box:hover .show2 {
  opacity: 1;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .prj {
    font-size: 1.5rem;
  }
  .prj span {
    font-size: 0.7rem;
  }
  .show1,
  .show2,
  .prj {
    padding: 5px 12px;
  }
}

@media screen and (max-width: 767px) {
  .gal-container {
    padding-left: 0px;
  }
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    padding: 10px 20px;
    padding-bottom: 60px;
  }
  .gallery .box {
    width: 100%;
    margin: auto;
  }
  .gallery img {
    opacity: 0.8;
  }
  .show1,
  .show2,
  .prj {
    opacity: 1;
    padding: 5px 12px;
  }
  .prj {
    font-size: 1.2rem;
  }
  .prj span {
    font-size: 0.5rem;
  }
  .show1 {
    left: 80%;
    bottom: 5%;
  }
  .show2 {
    left: 18%;
    bottom: 5%;
  }
  .gal-container .link,
  .gal-container i {
    max-height: 60px;
  }
}

@media screen and (max-width: 479px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .gallery div {
    margin: 0;
    width: 200px;
  }
}
